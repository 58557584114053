<template>
  <div class="vh-100">
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-primary">
        <home-icon class="icons"></home-icon>
      </router-link>
    </div>
    <section class="cover-user bg-white">
      <div class="row no-gutters vh-100">
        <div class="col-12 col-lg-4 login-form h-100 d-flex align-items-center px-4 order-2 order-lg-1">
          <div class="card login-page border-0" style="z-index: 1">
            <div class="card-body">
              <h4 class="card-title text-center">Login</h4>
              <form class="login-form mt-4" v-on:submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Your Email
                        <span class="text-danger">*</span></label>
                      <div class="position-relative">
                        <user-icon class="fea icon-sm icons"></user-icon>
                        <input type="email"
                          class="form-control pl-5"
                          v-bind:class="{ 'is-invalid': $v.form.email.$error, }"
                          placeholder="Email"
                          name="email"
                          v-model="$v.form.email.$model"/>
                      </div>
                      <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.email.$error, }">
                        <div v-if="!$v.form.email.required">This field is required</div>
                        <div v-if="!$v.form.email.email">This is not a valid email</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Password
                        <span class="text-danger">*</span></label>
                      <div class="position-relative">
                        <key-icon class="fea icon-sm icons"></key-icon>
                        <input type="password"
                          class="form-control pl-5"
                          v-bind:class="{ 'is-invalid': $v.form.password.$error, }"
                          placeholder="Password"
                          v-model="$v.form.password.$model"/>
                      </div>
                      <div class="invalid-feedback" v-bind:class="{ 'd-block': $v.form.password.$error, }">
                        <span v-if="!$v.form.password.required">This field is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customCheck1" />
                          <label class="custom-control-label" for="customCheck1">Remember me</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-0">
                    <button class="btn btn-primary btn-block" v-bind:disabled="loading">
                      Sign in
                    </button>
                  </div>
                  <div class="col-12 text-center">
                    <p class="mb-0 mt-3">
                      <small class="text-dark mr-2">Don't have an account ?</small>
                      <router-link to="/auth-cover-signup" class="text-dark font-weight-bold">Sign Up
                      </router-link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8 cover-img order-1 order-lg-2" style="background-image: url('/images/user/01.jpg')"></div>
      </div>
    </section>
  </div>
</template>

<script>
  import { HomeIcon, UserIcon, KeyIcon, } from "vue-feather-icons";
  import { validationMixin, } from "vuelidate";
  import { required, email, } from "vuelidate/lib/validators";
  import { auth, } from "@/services/firebase.service";
  import { mapError, } from "@/utils/map-errors";
  export default {
    name: "Login",
    mixins: [
      validationMixin,
    ],
    components: {
      HomeIcon,
      UserIcon,
      KeyIcon,
    },
    data() {
      return {
        loading: false,
        form: {
          email: "",
          password: "",
        },
      };
    },
    validations: {
      form: {
        email: {
          required, email,
        },
        password: {
          required,
        },
      },
    },
    methods: {
      onSubmit() {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        this.loading = true;
        auth.signInWithEmailAndPassword(this.form.email, this.form.password)
          .then(() => this.$router.replace("/my-account"))
          .catch(e => {
            console.error(e);
            this.$swal("Can't login", mapError(e.code), "error");
          })
          .finally(() => this.loading = false);

      }
    },
  };
</script>

<style lang="scss" scoped>
  .cover-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    &::before {
      background-color: rgba(0, 24, 113, .5);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media screen and (max-width: 991px) {
    .login-form {
      height: auto!important;
    }
    .cover-img {
      height: 40vh;
      max-height: 400px;
    }
  }
</style>